@import "https://fonts.googleapis.com/css2?family=Cousine:wght@400;700&display=swap";
*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: Cousine, ui-monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

h1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 1.5rem;
  line-height: 2rem;
}

h2 {
  padding-bottom: .5rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
}

a {
  --tw-text-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-text-opacity));
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

a:visited {
  color: #9333ea;
}

a:hover {
  --tw-text-opacity: 1;
  color: rgba(30, 64, 175, var(--tw-text-opacity));
}

textarea {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
}

*, :before, :after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, .5);
  --tw-ring-offset-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-ring-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::-webkit-backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, .5);
  --tw-ring-offset-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-ring-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, .5);
  --tw-ring-offset-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-ring-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.visible {
  visibility: visible;
}

.\!visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.z-10 {
  z-index: 10;
}

.m-8 {
  margin: 2rem;
}

.m-1 {
  margin: .25rem;
}

.m-3 {
  margin: .75rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.mx-3 {
  margin-left: .75rem;
  margin-right: .75rem;
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mt-2 {
  margin-top: .5rem;
}

.ml-auto {
  margin-left: auto;
}

.mt-12 {
  margin-top: 3rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.h-screen {
  height: 100vh;
}

.h-full {
  height: 100%;
}

.h-32 {
  height: 8rem;
}

.h-8 {
  height: 2rem;
}

.h-10 {
  height: 2.5rem;
}

.h-5 {
  height: 1.25rem;
}

.h-7 {
  height: 1.75rem;
}

.max-h-\[60rem\] {
  max-height: 60rem;
}

.min-h-\[40rem\] {
  min-height: 40rem;
}

.w-3\/12 {
  width: 25%;
}

.w-8\/12 {
  width: 66.6667%;
}

.w-3\/5 {
  width: 60%;
}

.w-px {
  width: 1px;
}

.w-2\/5 {
  width: 40%;
}

.w-\[60rem\] {
  width: 60rem;
}

.w-16 {
  width: 4rem;
}

.w-full {
  width: 100%;
}

.w-96 {
  width: 24rem;
}

.max-w-screen-2xl {
  max-width: 1536px;
}

.flex-auto {
  flex: auto;
}

.flex-none {
  flex: none;
}

.flex-grow {
  flex-grow: 1;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-evenly {
  justify-content: space-evenly;
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)) );
}

.space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2rem * var(--tw-space-x-reverse));
  margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)) );
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.overflow-auto {
  overflow: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.whitespace-pre {
  white-space: pre;
}

.rounded-sm {
  border-radius: .125rem;
}

.border {
  border-width: 1px;
}

.border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity));
}

.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
}

.border-gray-800 {
  --tw-border-opacity: 1;
  border-color: rgba(31, 41, 55, var(--tw-border-opacity));
}

.border-gray-600 {
  --tw-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}

.border-gray-700 {
  --tw-border-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-border-opacity));
}

.bg-black\/70 {
  background-color: rgba(0, 0, 0, .7);
}

.bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
}

.bg-black\/80 {
  background-color: rgba(0, 0, 0, .8);
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
}

.bg-inherit {
  background-color: inherit;
}

.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
}

.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
}

.bg-current {
  background-color: currentColor;
}

.bg-cover {
  background-size: cover;
}

.p-4 {
  padding: 1rem;
}

.p-8 {
  padding: 2rem;
}

.p-0 {
  padding: 0;
}

.p-2 {
  padding: .5rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.indent-8 {
  text-indent: 2rem;
}

.font-mono {
  font-family: Cousine, ui-monospace, monospace;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.text-gray-50 {
  --tw-text-opacity: 1;
  color: rgba(249, 250, 251, var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}

.text-ergodicity {
  --tw-text-opacity: 1;
  color: hsla(289, 50%, 40%, var(--tw-text-opacity));
}

.text-datalink {
  --tw-text-opacity: 1;
  color: hsla(210, 60%, 60%, var(--tw-text-opacity));
}

.text-spatial {
  --tw-text-opacity: 1;
  color: hsla(265, 60%, 60%, var(--tw-text-opacity));
}

.text-energy {
  --tw-text-opacity: 1;
  color: hsla(50, 60%, 60%, var(--tw-text-opacity));
}

.text-metacognition {
  --tw-text-opacity: 1;
  color: hsla(33, 50%, 50%, var(--tw-text-opacity));
}

.text-lethality {
  --tw-text-opacity: 1;
  color: hsla(0, 55%, 65%, var(--tw-text-opacity));
}

.text-yellow-300 {
  --tw-text-opacity: 1;
  color: rgba(253, 224, 71, var(--tw-text-opacity));
}

.text-green-300 {
  --tw-text-opacity: 1;
  color: rgba(134, 239, 172, var(--tw-text-opacity));
}

.text-red-400 {
  --tw-text-opacity: 1;
  color: rgba(248, 113, 113, var(--tw-text-opacity));
}

.text-green-400 {
  --tw-text-opacity: 1;
  color: rgba(74, 222, 128, var(--tw-text-opacity));
}

.text-red-300 {
  --tw-text-opacity: 1;
  color: rgba(252, 165, 165, var(--tw-text-opacity));
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-text-opacity));
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-text-opacity));
}

.opacity-50 {
  opacity: .5;
}

.opacity-30 {
  opacity: .3;
}

.outline {
  outline-style: solid;
}

.outline-0 {
  outline-width: 0;
}

.outline-1 {
  outline-width: 1px;
}

.outline-offset-1 {
  outline-offset: 1px;
}

.outline-gray-500 {
  outline-color: #6b7280;
}

.filter {
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-blur-\[6px\] {
  --tw-backdrop-blur: blur(6px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

html {
  background: linear-gradient(rgba(0, 0, 0, .4), rgba(0, 0, 0, .4)), url("background.9a27d8c4.jpg");
}

div.tooltip-container {
  --tooltipBackground: black;
  color: inherit;
}

div.tooltip-arrow:after {
  --tooltipBackground: black;
}

* {
  scrollbar-color: #999 #222;
}

::-webkit-scrollbar {
  width: initial;
}

::-webkit-scrollbar-track {
  background: #222;
}

::-webkit-scrollbar-thumb {
  background-color: #999;
}

.hover\:bg-gray-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
}

.hover\:bg-red-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
}

.tooltip-container {
  --tooltipBackground: #fff;
  --tooltipBorder: silver;
  --tooltipColor: #000;
  background-color: var(--tooltipBackground);
  border: 1px solid var(--tooltipBorder);
  color: var(--tooltipColor);
  z-index: 9999;
  border-radius: 3px;
  flex-direction: column;
  padding: .4rem;
  transition: opacity .3s;
  display: flex;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .18);
}

.tooltip-container[data-popper-interactive="false"] {
  pointer-events: none;
}

.tooltip-arrow {
  height: 1rem;
  width: 1rem;
  pointer-events: none;
  position: absolute;
}

.tooltip-arrow:before {
  content: "";
  height: 0;
  width: 0;
  border-style: solid;
  margin: auto;
  display: block;
}

.tooltip-arrow:after {
  content: "";
  height: 0;
  width: 0;
  border-style: solid;
  margin: auto;
  display: block;
  position: absolute;
}

.tooltip-container[data-popper-placement*="bottom"] .tooltip-arrow {
  margin-top: -.4rem;
  top: 0;
  left: 0;
}

.tooltip-container[data-popper-placement*="bottom"] .tooltip-arrow:before {
  border-color: transparent transparent var(--tooltipBorder) transparent;
  border-width: 0 .5rem .4rem;
  position: absolute;
  top: -1px;
}

.tooltip-container[data-popper-placement*="bottom"] .tooltip-arrow:after {
  border-color: transparent transparent var(--tooltipBackground) transparent;
  border-width: 0 .5rem .4rem;
}

.tooltip-container[data-popper-placement*="top"] .tooltip-arrow {
  margin-bottom: -1rem;
  bottom: 0;
  left: 0;
}

.tooltip-container[data-popper-placement*="top"] .tooltip-arrow:before {
  border-color: var(--tooltipBorder) transparent transparent transparent;
  border-width: .4rem .5rem 0;
  position: absolute;
  top: 1px;
}

.tooltip-container[data-popper-placement*="top"] .tooltip-arrow:after {
  border-color: var(--tooltipBackground) transparent transparent transparent;
  border-width: .4rem .5rem 0;
}

.tooltip-container[data-popper-placement*="right"] .tooltip-arrow {
  margin-left: -.7rem;
  left: 0;
}

.tooltip-container[data-popper-placement*="right"] .tooltip-arrow:before {
  border-color: transparent var(--tooltipBorder) transparent transparent;
  border-width: .5rem .4rem .5rem 0;
}

.tooltip-container[data-popper-placement*="right"] .tooltip-arrow:after {
  border-color: transparent var(--tooltipBackground) transparent transparent;
  border-width: .5rem .4rem .5rem 0;
  top: 0;
  left: 6px;
}

.tooltip-container[data-popper-placement*="left"] .tooltip-arrow {
  margin-right: -.7rem;
  right: 0;
}

.tooltip-container[data-popper-placement*="left"] .tooltip-arrow:before {
  border-color: transparent transparent transparent var(--tooltipBorder);
  border-width: .5rem 0 .5rem .4em;
}

.tooltip-container[data-popper-placement*="left"] .tooltip-arrow:after {
  border-color: transparent transparent transparent var(--tooltipBackground);
  border-width: .5rem 0 .5rem .4em;
  top: 0;
  left: 3px;
}

/*# sourceMappingURL=index.7abcd22c.css.map */
