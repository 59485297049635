/* TODO: shove this in the repo, don't import it from Google */
@import url('https://fonts.googleapis.com/css2?family=Cousine:wght@400;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url(./background.jpg);
}

@layer base {
  h1 {
    @apply text-2xl py-4;
  }

  h2 {
    @apply text-xl pb-2;
  }

  a {
   @apply underline text-blue-600 hover:text-blue-800 visited:text-purple-600
  }

  textarea {
    @apply bg-gray-900 border-gray-400 border;
  }
}

/* We use div. here in order to get extra specificity. */

div.tooltip-container {
  --tooltipBackground: black;
  color: inherit;
}

div.tooltip-arrow::after {
  --tooltipBackground: black;
}

/* it is 2022 and we are still having to do vendor prefixes, apparently */

* {
  scrollbar-color: #999999 #222222;
}

*::-webkit-scrollbar {
  width: initial;
}

*::-webkit-scrollbar-track {
  background: #222222;        /* color of the tracking area */
}

*::-webkit-scrollbar-thumb {
  background-color: #999999;    /* color of the scroll thumb */
}
